import React from "react";
import { Node } from "@mihhdu/api-client";
import { Link as URLLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import projectData from "../../project.json";
import { IsRootNode } from "../../utils/Utils";

const getPageNavAction = (
  hasChild: boolean,
  node: Node | null | undefined,
  tenant: string,
) => {
  return (
    <URLLink
      aria-haspopup={hasChild ? "listbox" : undefined}
      to={`${projectData.deployment.index}?nodeId=${node?.id}&tenant=${tenant}`}
    >
      {`${node?.data?.title}`}
    </URLLink>
  );
};

const renderNodeTree = (
  nodes: Node[],
  node: Node | null | undefined,
  tenant: string,
  elementCreator: Function = getPageNavAction,
) => {
  const hasChildren = (pn: Node | null | undefined) => {
    if (!pn?.outgoing_links) {
      return false;
    }
    return pn.outgoing_links.length > 0;
  };

  if (!hasChildren(node)) {
    return null;
  }

  const sortedLinks = node!.outgoing_links
    ?.map((l) => ({ link: l, childNode: nodes.find((n) => n.id === l.to) }))
    ?.sort((a, b) => {
      const hasChildrenA = hasChildren(a.childNode);
      const hasChildrenB = hasChildren(b.childNode);

      if (hasChildrenA && !hasChildrenB) {
        return -1; // a comes before b if a has children and b doesn't
      } else if (!hasChildrenA && hasChildrenB) {
        return 1; // b comes before a if b has children and a doesn't
      } else {
        return 0; // maintain the original order if both have children or both don't
      }
    });

  return (
    <ul>
      {sortedLinks?.map(({ link, childNode }) => {
        const hasChild = hasChildren(childNode);
        return (
          <li key={link.to} role={hasChild ? "list" : undefined}>
            {elementCreator(hasChild, childNode, tenant)}
            {renderNodeTree(nodes, childNode, tenant, elementCreator)}
          </li>
        );
      })}
    </ul>
  );
};

interface PageTreeProps {
  nodes: Node[];
  tenant: string;
}

interface MovePageTreeProps extends PageTreeProps {
  selectedNode: Node | null;
  handleNodeMove: (nodeId: string) => void;
  tenant: string;
}

export const PageTree: React.FC<PageTreeProps> = ({ nodes, tenant }) => {
  return (
    <aside>
      <details className="dropdown">
        <summary>
          <FaBarsStaggered />
        </summary>
        {renderNodeTree(
          nodes,
          nodes?.find((n) => IsRootNode(n)),
          tenant,
        )}
      </details>
    </aside>
  );
};

export const MovePageTree: React.FC<MovePageTreeProps> = ({
  nodes,
  selectedNode,
  handleNodeMove,
  tenant,
}) => {
  const getPageMoveAction = (
    hasChild: boolean,
    node: Node | null | undefined,
  ) => {
    if (
      node?.id !== selectedNode?.id &&
      node?.id !== selectedNode?.incoming_links![0]?.from
    ) {
      return (
        <p
          style={{ cursor: "pointer" }}
          key={node?.id}
          aria-haspopup={hasChild ? "listbox" : undefined}
          onClick={() => handleNodeMove(node?.id!)}
        >
          {`${node?.data?.title}`}
        </p>
      );
    }
    return (
      <s aria-haspopup={hasChild ? "listbox" : undefined}>
        {`${node?.data?.title}`}
      </s>
    );
  };

  return (
    <aside>
      <details className="dropdown">
        <summary role="button">{projectData.labels.menu.move_page}</summary>
        {renderNodeTree(
          nodes,
          nodes?.find((n) => IsRootNode(n)),
          tenant,
          getPageMoveAction,
        )}
      </details>
    </aside>
  );
};
