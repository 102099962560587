import { useState } from "react";
import projectData from "../project.json";

interface PageTagsProps {
  viewMode: boolean;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const ArticleFooterForm = ({ viewMode, tags, setTags }: PageTagsProps) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (indexToRemove: number) => {
    setTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove),
    );
  };

  if (viewMode && (tags == null || tags.length === 0)) return null;
  return (
    <footer>
      {viewMode ? null : (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter a tag and press Enter"
        />
      )}
      <div className="grid">
        <div>
          <h6>{projectData.tags}</h6>
        </div>
        {tags.map((tag, index) =>
          viewMode ? (
            <div key={index}>{tag}</div>
          ) : (
            <div key={index} onClick={() => handleRemoveTag(index)}>
              <span data-tooltip="Click to remove">{tag}</span>
            </div>
          ),
        )}
      </div>
    </footer>
  );
};

export default ArticleFooterForm;
