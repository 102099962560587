"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Auth = void 0;
const react_1 = require("react");
const api_client_1 = require("@mihhdu/api-client");
const token_storage_key = "token_response";
const Auth = ({ authServerBaseUrl, axiosInstance, setIsLoggedIn, tenant, }) => {
    const getAccessToken = () => {
        let token_val = sessionStorage.getItem(token_storage_key);
        if (token_val) {
            let token_response = JSON.parse(token_val);
            return token_response.access_token;
        }
        return null;
    };
    const getRefreshToken = () => {
        let token_val = sessionStorage.getItem(token_storage_key);
        if (token_val) {
            let token_response = JSON.parse(token_val);
            return token_response.refresh_token;
        }
        return null;
    };
    const isLoggedIn = () => {
        return !!sessionStorage.getItem(token_storage_key);
    };
    const config = new api_client_1.Configuration({
        basePath: authServerBaseUrl,
    });
    const authApi = new api_client_1.AuthApi(config, undefined, axiosInstance);
    const tryRefreshToken = async () => {
        const refresh_token = getRefreshToken();
        if (!refresh_token) {
            sessionStorage.removeItem(token_storage_key);
            setIsLoggedIn(false);
            return;
        }
        try {
            const tokenResponse = await authApi.refresh(tenant, "refresh_token", refresh_token);
            sessionStorage.setItem(token_storage_key, JSON.stringify(tokenResponse.data));
            return tokenResponse.data;
        }
        catch (error) {
            sessionStorage.removeItem(token_storage_key);
            setIsLoggedIn(false);
        }
    };
    const login = async (username, password) => {
        const tokenResponse = await authApi.login(tenant, username, password);
        if (tokenResponse.data) {
            sessionStorage.setItem(token_storage_key, JSON.stringify(tokenResponse.data));
            setIsLoggedIn(true);
        }
    };
    const logout = async () => {
        sessionStorage.removeItem(token_storage_key);
        await authApi.logout(tenant);
        setIsLoggedIn(false);
    };
    const getProfile = async () => {
        const profileResponse = await authApi.getProfile(tenant);
        return profileResponse.data;
    };
    const changeProfile = async (newProfile) => {
        return await authApi.updateProfile(tenant, newProfile);
    };
    (0, react_1.useEffect)(() => {
        const accessToken = getAccessToken();
        if (accessToken) {
            setIsLoggedIn(true);
        }
        else {
            setIsLoggedIn(false);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        const interceptors = axiosInstance.interceptors;
        const requestInterceptor = interceptors.request.use((config) => {
            const access_token = getAccessToken();
            if (access_token) {
                config.headers["Authorization"] = `Bearer ${access_token}`;
            }
            return config;
        }, (error) => Promise.reject(error));
        const responseInterceptor = interceptors.response.use((response) => response, async (error) => {
            var _a, _b;
            const config = error === null || error === void 0 ? void 0 : error.config;
            if ((((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 ||
                ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === 403) &&
                !(config === null || config === void 0 ? void 0 : config.sent)) {
                config.sent = true;
                const result = await tryRefreshToken();
                if (result === null || result === void 0 ? void 0 : result.access_token) {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${result.access_token}`,
                    };
                }
                return axiosInstance(config);
            }
            return Promise.reject(error);
        });
        return () => {
            interceptors.request.eject(requestInterceptor);
            interceptors.response.eject(responseInterceptor);
        };
    }, [axiosInstance, tryRefreshToken]);
    return {
        login,
        logout,
        getProfile,
        changeProfile,
        isLoggedIn,
        getAccessToken,
    };
};
exports.Auth = Auth;
