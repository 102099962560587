import React from "react";
import { IconType } from "react-icons";

interface ListItemWithIconProps {
  icon: IconType;
  tooltip?: string;
  onClick: () => void;
}

const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({
  icon: Icon,
  tooltip,
  onClick,
}) => {
  return (
    <li onClick={onClick}>
      <a href="#" data-tooltip={tooltip} className="list-align-center contrast">
        <Icon />
      </a>
    </li>
  );
};

export default ListItemWithIcon;
