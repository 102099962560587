import { Node } from "@mihhdu/api-client";

const RootNodeId = "root";

const IsRootNode = (node: Node) => {
  return IsRootId(node?.id);
};

const IsRootId = (nodeId: string) => {
  if (nodeId == null) return false;
  return nodeId === "root";
};

export { RootNodeId, IsRootId, IsRootNode };
