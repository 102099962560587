import { Link as URLLink } from "react-router-dom";
import { Node } from "@mihhdu/api-client";
import { IoIosHome } from "react-icons/io";
import projectData from "../../project.json";
import { IsRootNode } from "../../utils/Utils";

const RenderParentItem = (
  nodes: Node[],
  startNode: Node | null,
  current: Node | null | undefined = null,
  tenant: string,
): JSX.Element | null => {
  const hasParent = (node: Node | null | undefined) =>
    node?.incoming_links && node.incoming_links.length > 0;

  current ??= startNode;
  // Exclude root node
  if (!current || !hasParent(current)) {
    return null;
  }

  const parent = nodes.find((n) => n.id === current!.incoming_links![0]?.from);

  if (!parent) {
    return null;
  }

  return (
    <>
      {RenderParentItem(nodes, startNode, parent, tenant)}
      {startNode!.id !== current.id && (
        <li key={current.id}>
          <URLLink
            to={`${projectData.deployment.index}?nodeId=${current.id}&tenant=${tenant}`}
            className="contrast"
          >
            <strong>{`${current.data?.title}`}</strong>
          </URLLink>
        </li>
      )}
    </>
  );
};

interface BreadCrumbProps {
  nodes: Node[];
  selectedNode: Node | null | undefined;
  tenant: string;
}

const BreadCrumbNav: React.FC<BreadCrumbProps> = ({
  nodes,
  selectedNode,
  tenant,
}) => {
  // Check if it's the root node, and exclude it
  if (!selectedNode || IsRootNode(selectedNode)) {
    return null;
  }

  return (
    <nav aria-label="breadcrumb">
      <ul>
        <li>
          <URLLink
            to={`${projectData.deployment.index}?nodeId=root&tenant=${tenant}`}
            className="contrast"
          >
            <IoIosHome />
          </URLLink>
        </li>
        {RenderParentItem(nodes, selectedNode, null, tenant)}
      </ul>
    </nav>
  );
};

export default BreadCrumbNav;
