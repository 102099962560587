import { Editor } from "@tiptap/react";
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaItalic,
  FaBold,
  FaStrikethrough,
  FaUnderline,
  FaLink,
  Fa1,
  Fa2,
  Fa3,
  FaLaptopCode,
  Fa4,
} from "react-icons/fa6";

interface BubbleMenuBarProps {
  editor: Editor;
  setLink: () => void;
}

export function BubbleMenuBar({ editor, setLink }: BubbleMenuBarProps) {
  return (
    <div className="bubble-menu">
      <input
        type="color"
        onInput={(event) =>
          editor
            .chain()
            .focus()
            .setColor((event.target as HTMLInputElement).value)
            .run()
        }
        value={editor.getAttributes("textStyle").color}
      />
      <button
        data-tooltip="Align left"
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        className={
          editor.isActive({ textAlign: "left" }) ? "contrast" : "secondary"
        }
      >
        <FaAlignLeft />
      </button>
      <button
        data-tooltip="Align center"
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        className={
          editor.isActive({ textAlign: "center" }) ? "contrast" : "secondary"
        }
      >
        <FaAlignCenter />
      </button>
      <button
        data-tooltip="Align right"
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        className={
          editor.isActive({ textAlign: "right" }) ? "contrast" : "secondary"
        }
      >
        <FaAlignRight />
      </button>
      <button
        data-tooltip="Justify"
        onClick={() => editor.chain().focus().setTextAlign("justify").run()}
        className={
          editor.isActive({ textAlign: "justify" }) ? "contrast" : "secondary"
        }
      >
        <FaAlignJustify />
      </button>
      <button
        data-tooltip="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "contrast" : "secondary"}
      >
        <FaBold />
      </button>
      <button
        data-tooltip="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "contrast" : "secondary"}
      >
        <FaItalic />
      </button>
      <button
        data-tooltip="Strikethrough"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "contrast" : "secondary"}
      >
        <FaStrikethrough />
      </button>
      <button
        data-tooltip="Underline"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive("underline") ? "contrast" : "secondary"}
      >
        <FaUnderline />
      </button>
      <button
        data-tooltip="Heading 1"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          editor.isActive("heading", { level: 1 }) ? "contrast" : "secondary"
        }
      >
        <Fa1 />
      </button>
      <button
        data-tooltip="Heading 2"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          editor.isActive("heading", { level: 2 }) ? "contrast" : "secondary"
        }
      >
        <Fa2 />
      </button>
      <button
        data-tooltip="Heading 3"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={
          editor.isActive("heading", { level: 3 }) ? "contrast" : "secondary"
        }
      >
        <Fa3 />
      </button>
      <button
        data-tooltip="Heading 4"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={
          editor.isActive("heading", { level: 4 }) ? "contrast" : "secondary"
        }
      >
        <Fa4 />
      </button>
      <button
        data-tooltip="Code"
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "contrast" : "secondary"}
      >
        <FaLaptopCode />
      </button>
      <button
        data-tooltip="Link"
        onClick={setLink}
        className={editor.isActive("link") ? "contrast" : "secondary"}
      >
        <FaLink />
      </button>
    </div>
  );
}
