import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./style/App.css";
import "./style/pico.custom.scss";
import projectData from "./project.json";
import {
  NodesApi,
  LinksApi,
  Node,
  Configuration,
  UserProfile,
  StorageApi,
} from "@mihhdu/api-client";
import {
  Auth,
  Register,
  LoginForm,
  RegisterForm,
  PasswordChangeForm,
  ProfileForm,
  Tenants,
  TenantList,
} from "@mihhdu/shared";
import { EditorContent, BubbleMenu, FloatingMenu } from "@tiptap/react";
import Operation from "./components/Operation";
import {
  CustomEditor,
  BubbleMenuBar,
  FloatingMenuBar,
} from "./components/editor";
import ArticleFooterForm from "./components/ArticleFooter";
import SiteFooter from "./components/SiteFooter";
import { IsRootNode, RootNodeId } from "./utils/Utils";
import UserMenuForm from "./components/navbar/UserMenu";
import BreadCrumbNav from "./components/navbar/BreadCrumb";
import { TableMenuBar } from "./components/editor/TableMenuBar";
import { MovePageTree, PageTree } from "./components/navbar/PageTree";
import ListItemWithIcon from "./components/navbar/ListItemWithIcon";
import { IoCreateOutline } from "react-icons/io5";
import {
  MdDeleteForever,
  MdDriveFileMoveOutline,
  MdEdit,
} from "react-icons/md";
import { GiCancel, GiConfirmed } from "react-icons/gi";
import { GrUpdate } from "react-icons/gr";

function App() {
  const indexPage = projectData.deployment.index;
  const nodeIdParamName = "nodeId";
  const tenantParamName = "tenant";

  const navigate = useNavigate();
  const redirect = (nodeId: string, tenant: string) => {
    navigate({
      pathname: indexPage,
      search: `?${nodeIdParamName}=${nodeId}&${tenantParamName}=${tenant}`,
    });
  };

  const redirectHome = (tenant: string) => {
    navigate({
      pathname: indexPage,
      search: `?${nodeIdParamName}=root&${tenantParamName}=${tenant}`,
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nodeId = searchParams.get(nodeIdParamName);
  const tenant =
    searchParams.get(tenantParamName) ??
    localStorage.getItem("tenant") ??
    "default";

  const [nodes, setNodes] = useState<Node[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [htmlPageTitle, setHtmlPageTitle] = useState<string>("");
  const [htmlPageBody, setHtmlPageBody] = useState<string>("");
  const [pageTags, setPageTags] = useState<string[]>([]);
  const [editingMode, setEditingMode] = useState<Operation>(Operation.View);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);
  const [tenantModalOpen, setTenantModalOpen] = useState<boolean>(false);

  const {
    editor,
    editorBody,
    addImage,
    setLink,
    addAudio,
    addVideo,
    addYoutube,
    addIFrame,
  } = CustomEditor();

  // Set up the configuration with the Axios instance
  const config = new Configuration({
    basePath: process.env.REACT_APP_SERVER_URL,
  });
  const axiosInstance = axios.create();
  const {
    login,
    logout,
    getProfile,
    changeProfile,
    isLoggedIn: getIsLoggedIn,
  } = Auth({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
    setIsLoggedIn,
    tenant,
  });

  const { register, get } = Register({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
  });

  const { getTenants } = Tenants({
    authServerBaseUrl: process.env.REACT_APP_SERVER_URL!,
    axiosInstance,
  });

  const { tenantListForm } = TenantList({
    baseUrl: process.env.REACT_APP_SERVER_URL!,
    getTenants,
    onClose: () => {
      setTenantModalOpen(false);
    },
  });

  const { profileForm } = ProfileForm({
    callback: () => {
      setProfileModalOpen(false);
    },
    success: async () => {
      const profile = await getProfile();
      setUserProfile(profile);
    },
    profile: userProfile,
    changeProfile: async (
      firstName: string,
      lastName: string,
      email: string,
    ) => {
      return await changeProfile({
        first_name: firstName,
        last_name: lastName,
        email: email,
      });
    },
  });

  const { passwordChangeForm } = PasswordChangeForm({
    callback: () => {
      setChangePasswordModalOpen(false);
    },
    success: () => {
      logout();
    },
    changePassword: async (oldPassword: string, password: string) => {
      return await changeProfile({
        old_password: oldPassword,
        password: password,
      });
    },
  });
  const { loginForm } = LoginForm({
    callback: () => {
      setLoginModalOpen(false);
    },
    login,
  });

  const { registerForm, newTenant } = RegisterForm({
    baseUrl: process.env.REACT_APP_SERVER_URL!,
    callback: () => {
      setRegisterModalOpen(false);
    },
    success: () => {
      if (newTenant !== null && newTenant !== undefined)
        redirectHome(newTenant);
    },
    register,
  });

  // Add logging interceptor for Axios
  axiosInstance.interceptors.request.use((config) => {
    console.log("Request:", config);
    return config;
  });

  const nodeApi = new NodesApi(config, undefined, axiosInstance);
  const linkApi = new LinksApi(config, undefined, axiosInstance);
  const storageApi = new StorageApi(config, undefined, axiosInstance);

  const setNodeState = (node: Node) => {
    setSelectedNode(node);
    setHtmlPageTitle(node?.data?.title || "");
    setHtmlPageBody(node?.data?.body || "");
    setPageTags(node?.data?.tags || []);
    setEditingMode(Operation.View);
  };

  useEffect(() => {
    // Set item
    localStorage.setItem("tenant", tenant);
  }, [tenant]);

  useEffect(() => {
    const trySetProfile = async () => {
      // If the user is logged in, fetch the profile data
      if (isLoggedIn) {
        try {
          const profile = await getProfile();
          setUserProfile(profile);
        } catch (error) {
          console.log("failed to fetch profile" + error);
        }
      } else {
        setUserProfile(null);
      }
    };

    trySetProfile();
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchNode = async (id: string | null) => {
      if (id) {
        try {
          const response = await nodeApi.getNodeById(tenant, id);
          return response.data;
        } catch (error) {
          console.log("Error fetch node:", error);
        }
      }
      return null;
    };

    const fetchNodeUpdateState = async () => {
      const n = await fetchNode(nodeId);
      // Use a default object if fetchNode returns null
      const defaultNodeObject = {
        id: nodeId,
        data: {
          title: projectData.errors.not_found_title,
          body: `<h4>${projectData.errors.not_found_body}</h4>`,
        },
      } as Node;

      // Use the fetchedNode if it exists, otherwise use the defaultNodeObject
      const nodeToUpdateState: Node = n ?? defaultNodeObject;

      setNodeState(nodeToUpdateState);
    };

    if (nodeId !== null) {
      fetchNodeUpdateState();
    }
  }, [nodeId, tenant]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        console.log(process.env.NODE_ENV);
        setIsLoggedIn(getIsLoggedIn());
        const nodesResponse = await nodeApi.getNodes(tenant);
        setNodes(nodesResponse.data);
        const root = await nodeApi.getNodeById(tenant, RootNodeId);
        if (!nodeId) {
          // if we are loading the app through a url other than index.html or /
          setSelectedNode(root.data);
          setHtmlPageBody(root?.data?.data?.body || "");
          setPageTags(root?.data?.data?.tags || []);
        }
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    };

    fetchNodes();
  }, [tenant]);

  function getRandomInteger(min: number, max: number): number {
    const range = max - min + 1;
    const random = Math.random();
    const randomNumber = Math.floor(random * range) + min;
    return randomNumber;
  }

  const renderTitleSection = () => {
    if (selectedNode == null) return null;
    const renderTitle = () => {
      switch (editingMode) {
        case Operation.Delete:
        case Operation.View:
        case Operation.Move:
          return selectedNode.data?.title;
        case Operation.Update:
        case Operation.CreateNode:
          return (
            <input
              value={htmlPageTitle}
              onChange={(e) => setHtmlPageTitle(e.target.value)}
            />
          );
        default:
          break;
      }
    };
    return (
      <hgroup>
        <h2>{renderTitle()}</h2>
      </hgroup>
    );
  };

  const renderArticleContent = () => {
    const renderArticleHeader = () => {
      if (
        selectedNode.data?.author == null ||
        selectedNode.data?.author.trim() === ""
      )
        return null;

      return (
        <header>
          <h6>
            Posted by <b>{selectedNode.data?.author}</b> on{" "}
            {selectedNode.data?.created}
          </h6>
        </header>
      );
    };

    const renderCreateEdit = () => {
      if (editor == null) return <></>;
      return (
        <div>
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            {BubbleMenuBar({
              editor,
              setLink,
            })}
          </BubbleMenu>
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            shouldShow={({ editor, view, state, oldState, from, to }) => {
              return (
                editor.isActive("table") ||
                editor.isActive("tableCell") ||
                editor.isActive("tableHeader") ||
                editor.isActive("tableRow")
              );
            }}
          >
            {TableMenuBar(editor)}
          </BubbleMenu>
          <FloatingMenu
            editor={editor}
            tippyOptions={{ duration: 100, placement: "top" }}
          >
            {FloatingMenuBar({
              addImage,
              addAudio,
              addVideo,
              addYoutube,
              addIFrame,
              editor,
              storage: storageApi,
              tenant,
            })}
          </FloatingMenu>
          <EditorContent editor={editor} />
        </div>
      );
    };

    switch (editingMode) {
      case Operation.Delete:
      case Operation.View:
      case Operation.Move:
        return (
          <>
            {renderArticleHeader()}
            <div dangerouslySetInnerHTML={{ __html: htmlPageBody }} />
          </>
        );
      case Operation.CreateNode:
      case Operation.Update:
        return renderCreateEdit();
      default:
        break;
    }
  };

  const renderControlsMenu = () => {
    if (!isLoggedIn) return <></>;

    const cancel = () => {
      setHtmlPageBody(selectedNode?.data?.body!);
      setHtmlPageTitle(selectedNode?.data?.title!);
      setPageTags(selectedNode?.data?.tags || []);
      setEditingMode(Operation.View);
    };
    const confirmAction = () => {
      switch (editingMode) {
        case Operation.View:
          return (
            <>
              <ListItemWithIcon
                tooltip="Edit"
                icon={MdEdit}
                onClick={() => {
                  setHtmlPageTitle(selectedNode.data?.title);
                  editor?.commands.setContent(htmlPageBody, true);
                  setEditingMode(Operation.Update);
                }}
              />
              {!IsRootNode(selectedNode) && (
                <>
                  <ListItemWithIcon
                    tooltip="Move"
                    icon={MdDriveFileMoveOutline}
                    onClick={() => {
                      setEditingMode(Operation.Move);
                    }}
                  />
                  <ListItemWithIcon
                    tooltip="Delete"
                    icon={MdDeleteForever}
                    onClick={() => {
                      setEditingMode(Operation.Delete);
                    }}
                  />
                </>
              )}
            </>
          );
        case Operation.Delete:
          return (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.delete_page}
                icon={MdDeleteForever}
                onClick={async () => {
                  if (selectedNode) {
                    try {
                      selectedNode.outgoing_links?.forEach(async (element) => {
                        await linkApi.addLink(tenant, {
                          from: selectedNode.incoming_links![0].from,
                          to: element.to,
                          weight: element.weight,
                        });
                      });
                      await nodeApi.deleteNode(tenant, selectedNode.id!);
                      const nodes = await nodeApi.getNodes(tenant);
                      setNodes(nodes.data);
                      redirectHome(tenant);
                    } catch (error) {
                      console.error("Error deleting node: ", error);
                    }
                  }
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          );
        case Operation.Update:
          return (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.update_page}
                icon={GrUpdate}
                onClick={async () => {
                  if (selectedNode && editingMode === Operation.Update) {
                    try {
                      const updatedNode = await nodeApi.updateNodeById(
                        tenant,
                        selectedNode?.id!,
                        {
                          data: {
                            body: editorBody,
                            title: htmlPageTitle,
                            tags: pageTags,
                          },
                        },
                      );
                      setNodeState(updatedNode.data);
                      const nodesWithoutUpdated = nodes.filter(
                        (n) => n.id !== selectedNode?.id,
                      );
                      const readdWithInfo = nodesWithoutUpdated.concat(
                        updatedNode.data,
                      );
                      setNodes(readdWithInfo);
                    } catch (error) {
                      console.error("Error updating node data:", error);
                    }
                  }
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          );
        case Operation.CreateNode:
          return (
            <>
              <ListItemWithIcon
                tooltip={projectData.labels.menu.confirm_page}
                icon={GiConfirmed}
                onClick={async () => {
                  const createNode = async () => {
                    try {
                      const response = await nodeApi.addNode(tenant, {
                        data: {
                          body: editorBody,
                          title: htmlPageTitle,
                          tags: pageTags,
                        },
                        incoming_links: [
                          {
                            from: selectedNode?.id,
                            to: "",
                            weight: getRandomInteger(1, 100000),
                          },
                        ],
                      });
                      return response.data;
                    } catch (error) {
                      console.error("Error creating node: ", error);
                    }
                  };

                  const node = await createNode();
                  if (node) {
                    const nodes = await nodeApi.getNodes(tenant);
                    setNodes(nodes.data);
                    redirect(node.id!, tenant);
                  }
                }}
              />
              <ListItemWithIcon
                tooltip={projectData.labels.menu.cancel}
                icon={GiCancel}
                onClick={cancel}
              />
            </>
          );
        case Operation.Move:
          return (
            <ListItemWithIcon
              tooltip={projectData.labels.menu.cancel}
              icon={GiCancel}
              onClick={cancel}
            />
          );
        default:
          return <></>;
      }
    };

    return (
      <nav>
        <ul></ul>
        <ul>{confirmAction()}</ul>
      </nav>
    );
  };

  const renderMenu = () => {
    switch (editingMode) {
      case Operation.Move:
        return (
          <nav>
            <ul>
              {MovePageTree({
                nodes,
                selectedNode,
                tenant,
                handleNodeMove: async (newNodeId: string) => {
                  if (selectedNode && editingMode === Operation.Move) {
                    try {
                      selectedNode?.incoming_links?.forEach(async (l) => {
                        await linkApi.deleteLink(
                          tenant,
                          l.weight!,
                          l.from!,
                          l.to!,
                        );
                      });
                      await linkApi.addLink(tenant, {
                        weight: getRandomInteger(1, 100000),
                        from: newNodeId,
                        to: selectedNode.id,
                      });
                      const nodes = await nodeApi.getNodes(tenant);
                      setNodes(nodes.data);
                      setSelectedNode(
                        nodes.data.find((x) => x.id === selectedNode.id),
                      );
                      setEditingMode(Operation.View);
                    } catch (error) {
                      console.error("Error moving node:", error);
                    }
                  }
                },
              })}
            </ul>
          </nav>
        );
      default:
        return (
          <nav>
            <ul>
              <li>{PageTree({ nodes, tenant })}</li>
            </ul>
          </nav>
        );
    }
  };

  return (
    <>
      <header>
        {renderTitleSection()}
        <nav>
          <ul>
            <li>{renderMenu()}</li>
          </ul>
          <ul>
            {isLoggedIn && editingMode === Operation.View && (
              <ListItemWithIcon
                tooltip={projectData.labels.menu.create_page}
                icon={IoCreateOutline}
                onClick={() => {
                  setHtmlPageTitle(projectData.labels.new_page_title_template);
                  setPageTags([]);
                  editor?.commands.setContent(
                    projectData.labels.new_page_body_template,
                    true,
                  );
                  setEditingMode(Operation.CreateNode);
                }}
              />
            )}
            {UserMenuForm({
              isLoggedIn,
              getProfile: () => userProfile,
              openLoginForm: () => {
                setLoginModalOpen(true);
              },
              openRegisterForm: () => {
                setRegisterModalOpen(true);
              },
              openProfileForm: () => {
                setProfileModalOpen(true);
              },
              openPasswordChangeForm: () => {
                setChangePasswordModalOpen(true);
              },
              openTenantsForm: () => {
                setTenantModalOpen(true);
              },
              logout: async () => logout(),
            })}
          </ul>
        </nav>
        <BreadCrumbNav
          nodes={nodes}
          selectedNode={selectedNode}
          tenant={tenant}
        />
      </header>
      <main className="container">
        {loginModalOpen ? loginForm() : null}
        {registerModalOpen ? registerForm() : null}
        {profileModalOpen ? profileForm() : null}
        {changePasswordModalOpen ? passwordChangeForm() : null}
        {tenantModalOpen ? tenantListForm() : null}
        {selectedNode && (
          <>
            {renderControlsMenu()}
            <article className="view-mode">
              {renderArticleContent()}
              <ArticleFooterForm
                viewMode={
                  editingMode === Operation.View ||
                  editingMode === Operation.Move ||
                  editingMode === Operation.Delete
                }
                tags={pageTags}
                setTags={setPageTags}
              />
            </article>
            {renderControlsMenu()}
          </>
        )}
      </main>
      {SiteFooter()}
    </>
  );
}

export default App;
