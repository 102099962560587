"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tenants = void 0;
const api_client_1 = require("@mihhdu/api-client");
const Tenants = ({ authServerBaseUrl, axiosInstance }) => {
    const config = new api_client_1.Configuration({
        basePath: authServerBaseUrl,
    });
    const tenantsApi = new api_client_1.TenantsApi(config, undefined, axiosInstance);
    const getTenants = async () => {
        const tenants = await tenantsApi.tenantsGet();
        return tenants.data;
    };
    return { getTenants };
};
exports.Tenants = Tenants;
