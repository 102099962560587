"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const isOpenClass = "modal-is-open";
const openingClass = "modal-is-opening";
const closingClass = "modal-is-closing";
const animationDuration = 400;
const Modal = () => {
    const [visibleModal, setVisibleModal] = (0, react_1.useState)(null);
    const isModalOpen = (modal) => {
        return ((modal === null || modal === void 0 ? void 0 : modal.hasAttribute("open")) && modal.getAttribute("open") !== "false");
    };
    const openModal = (modal) => {
        if (isScrollbarVisible()) {
            document.documentElement.style.setProperty("--scrollbar-width", `${getScrollbarWidth()}px`);
        }
        document.documentElement.classList.add(isOpenClass, openingClass);
        setTimeout(() => {
            setVisibleModal(modal);
            document.documentElement.classList.remove(openingClass);
        }, animationDuration);
        modal === null || modal === void 0 ? void 0 : modal.setAttribute("open", "true");
    };
    const closeModal = (modal) => {
        setVisibleModal(null);
        document.documentElement.classList.add(closingClass);
        setTimeout(() => {
            document.documentElement.classList.remove(closingClass, isOpenClass);
            document.documentElement.style.removeProperty("--scrollbar-width");
            modal === null || modal === void 0 ? void 0 : modal.removeAttribute("open");
        }, animationDuration);
    };
    (0, react_1.useEffect)(() => {
        const clickOutsideListener = (event) => {
            if (visibleModal !== null) {
                const modalContent = visibleModal.querySelector("article");
                if (modalContent) {
                    const isClickInside = modalContent.contains(event.target);
                    if (!isClickInside) {
                        closeModal(visibleModal);
                    }
                }
            }
        };
        const escKeyListener = (event) => {
            if (event.key === "Escape" && visibleModal !== null) {
                closeModal(visibleModal);
            }
        };
        document.addEventListener("click", clickOutsideListener);
        document.addEventListener("keydown", escKeyListener);
        return () => {
            document.removeEventListener("click", clickOutsideListener);
            document.removeEventListener("keydown", escKeyListener);
        };
    }, [visibleModal]);
    const getScrollbarWidth = () => {
        var _a;
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll";
        document.body.appendChild(outer);
        const inner = document.createElement("div");
        outer.appendChild(inner);
        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
        (_a = outer.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(outer);
        return scrollbarWidth;
    };
    const isScrollbarVisible = () => {
        return document.body.scrollHeight > window.innerHeight;
    };
    const toggleModal = (event) => {
        event.preventDefault();
        const modalId = event.currentTarget.getAttribute("data-target");
        const modal = document.getElementById(modalId);
        if (modal) {
            if (isModalOpen(modal)) {
                closeModal(modal);
            }
            else {
                openModal(modal);
            }
        }
    };
    return { toggleModal };
};
exports.default = Modal;
