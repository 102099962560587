import React, { MouseEvent } from "react";
import { useTheme } from "../contexts/ThemeContext";
import IconMoon from "../icons/IconMoon";
import IconSun from "../icons/IconSun";

interface ColorSchemeSwitcherProps extends React.HTMLProps<HTMLAnchorElement> {}

const ColorSchemeSwitcher: React.FC<ColorSchemeSwitcherProps> = (props) => {
  const { switchTheme, theme } = useTheme();
  const nextTheme = theme === "dark" ? "light" : "dark";
  const nextThemeLabel =
    theme === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode";

  const handleSwitchTheme = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    switchTheme();
  };

  return (
    <a
      data-tooltip={nextThemeLabel}
      href={`#${nextTheme}`}
      aria-label={nextThemeLabel}
      onClick={handleSwitchTheme}
      {...props}
    >
      {theme === "dark" ? <IconSun /> : <IconMoon />}
    </a>
  );
};

export default ColorSchemeSwitcher;
