import React from "react";
import { IconType } from "react-icons";

interface ItemWithIconProps {
  icon: IconType;
  text?: string;
}

export const IconWithTextLink: React.FC<ItemWithIconProps> = ({
  icon: Icon,
  text,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <a href="#" style={{ display: "flex", alignItems: "center" }}>
        <Icon style={{ verticalAlign: "middle", marginRight: "5px" }} />
        <span>{text}</span>
      </a>
    </div>
  );
};

export const IconWithText: React.FC<ItemWithIconProps> = ({
  icon: Icon,
  text,
}) => {
  return (
    <>
      <Icon style={{ verticalAlign: "middle", marginRight: "5px" }} />
      <span>{text}</span>
    </>
  );
};
