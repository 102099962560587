import { UserProfile } from "@mihhdu/api-client";
import { FaRegCircleUser, FaCircleUser } from "react-icons/fa6";
import { MdLogin, MdLogout } from "react-icons/md";
import ColorSchemeSwitcher from "../ColorSchemeSwitcher";
import { IconWithTextLink, IconWithText } from "./IconWithText";
import { LiaRegistered } from "react-icons/lia";
import { ImProfile } from "react-icons/im";
import { PiPasswordLight } from "react-icons/pi";
import { CgWebsite } from "react-icons/cg";
import { useEffect, useState } from "react";

interface UserMenuProps {
  isLoggedIn: boolean;
  getProfile: () => UserProfile | null;
  openLoginForm: () => void;
  openRegisterForm: () => void;
  openProfileForm: () => void;
  openPasswordChangeForm: () => void;
  openTenantsForm: () => void;
  logout: () => void;
}

const UserMenuForm = ({
  isLoggedIn,
  getProfile,
  openLoginForm,
  openRegisterForm,
  openProfileForm,
  openPasswordChangeForm,
  openTenantsForm,
  logout,
}: UserMenuProps) => {
  const [profile, setProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchedProfile = getProfile();
      console.log("got profile " + fetchedProfile);
      setProfile(fetchedProfile);
    } else {
      setProfile(null);
    }
  }, [isLoggedIn, getProfile]);

  return (
    <>
      <li>
        <details className="dropdown">
          <summary>
            {isLoggedIn ? (
              <IconWithText
                icon={FaCircleUser}
                text={`Hi ${profile?.username}`}
              />
            ) : (
              <IconWithText icon={FaRegCircleUser} text="Account" />
            )}
          </summary>
          <ul>
            {isLoggedIn ? (
              <>
                <li onClick={openProfileForm}>
                  <IconWithTextLink icon={ImProfile} text="Profile" />
                </li>
                <li onClick={openPasswordChangeForm}>
                  <IconWithTextLink icon={PiPasswordLight} text="Password" />
                </li>
                <li onClick={logout}>
                  <IconWithTextLink icon={MdLogout} text="Logout" />
                </li>
              </>
            ) : (
              <>
                <li onClick={openLoginForm}>
                  <IconWithTextLink icon={MdLogin} text="Login" />
                </li>
                <li onClick={openRegisterForm}>
                  <IconWithTextLink icon={LiaRegistered} text="Register" />
                </li>
              </>
            )}
            <li onClick={openTenantsForm}>
              <IconWithTextLink icon={CgWebsite} text="Spaces" />
            </li>
          </ul>
        </details>
      </li>
      <li>
        <ColorSchemeSwitcher className="list-align-center contrast" />
      </li>
    </>
  );
};
export default UserMenuForm;
