"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Register = void 0;
const api_client_1 = require("@mihhdu/api-client");
const Register = ({ authServerBaseUrl, axiosInstance, }) => {
    const config = new api_client_1.Configuration({
        basePath: authServerBaseUrl,
    });
    const tenantsApi = new api_client_1.TenantsApi(config, undefined, axiosInstance);
    const register = async (tenantId, username, password) => {
        return await tenantsApi.createTenant({
            tenant_id: tenantId,
            username: username,
            password: password,
        });
    };
    const get = async () => {
        return await tenantsApi.tenantsGet();
    };
    return {
        register,
        get,
    };
};
exports.Register = Register;
