import React, { useState } from "react";
import { StorageApi } from "@mihhdu/api-client";

interface FileUploadProps {
  onUploadComplete: (htmlTag: string) => void;
  storage: StorageApi;
  tenant: string;
}

function FileUpload({ onUploadComplete, storage, tenant }: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        setIsUploadInProgress(true);
        await storage.tenantUploadPost(
          tenant,
          `filename="${selectedFile.name}"`,
          selectedFile,
        );
        const url = `media/${tenant}/${selectedFile.name}`;

        // Determine the appropriate tag based on the file type (MIME type)
        let htmlTag = "";
        console.log(selectedFile.type);
        if (selectedFile.type.startsWith("video")) {
          htmlTag = `<video src="${url}" controls></video>`;
        } else if (selectedFile.type.startsWith("audio")) {
          htmlTag = `<audio src="${url}" controls></audio>`;
        } else if (selectedFile.type.startsWith("image")) {
          htmlTag = `<figure><img src="${url}" alt="Image"><figcaption>Image file</figcaption></figure>`;
        } else {
          console.error("Unsupported file type.");
          return;
        }
        // Call the onUploadComplete callback with the HTML tag
        onUploadComplete(htmlTag);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsUploadInProgress(false);
      }
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .webp, .bmp, .svg, .tiff, .ico, .mp3, .ogg, .wav, .flac, .aac, .wma, .m4a, .opus, .aif, .aiff, .mp2, .mka"
        onChange={handleFileChange}
      />
      <button
        data-tooltip="Upload and insert media file"
        aria-busy={isUploadInProgress}
        onClick={handleFileUpload}
      >
        {isUploadInProgress ? "Loading..." : "Upload media file"}
      </button>
    </>
  );
}

export default FileUpload;
