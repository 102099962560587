import { Editor } from "@tiptap/react";
import {
  TbArrowBadgeLeft,
  TbArrowBadgeRight,
  TbColumnInsertLeft,
  TbColumnInsertRight,
  TbColumnRemove,
  TbRowInsertBottom,
  TbRowInsertTop,
  TbRowRemove,
  TbTableColumn,
  TbTableDown,
  TbTableFilled,
  TbTableMinus,
  TbTableRow,
  TbTableShortcut,
} from "react-icons/tb";

export function TableMenuBar(editor: Editor) {
  return (
    <>
      {editor.can().addColumnBefore() && (
        <button
          data-tooltip="Add column before"
          className="secondary"
          onClick={() => editor.chain().focus().addColumnBefore().run()}
        >
          <TbColumnInsertLeft />
        </button>
      )}
      {editor.can().addColumnAfter() && (
        <button
          data-tooltip="Add column after"
          className="secondary"
          onClick={() => editor.chain().focus().addColumnAfter().run()}
        >
          <TbColumnInsertRight />
        </button>
      )}
      {editor.can().deleteColumn() && (
        <button
          data-tooltip="Delete column"
          className="secondary"
          onClick={() => editor.chain().focus().deleteColumn().run()}
        >
          <TbColumnRemove />
        </button>
      )}
      {editor.can().addRowBefore() && (
        <button
          data-tooltip="Add row before"
          className="secondary"
          onClick={() => editor.chain().focus().addRowBefore().run()}
        >
          <TbRowInsertTop />
        </button>
      )}
      {editor.can().addRowAfter() && (
        <button
          data-tooltip="Add row after"
          className="secondary"
          onClick={() => editor.chain().focus().addRowAfter().run()}
        >
          <TbRowInsertBottom />
        </button>
      )}
      {editor.can().deleteRow() && (
        <button
          data-tooltip="Delete row"
          className="secondary"
          onClick={() => editor.chain().focus().deleteRow().run()}
        >
          <TbRowRemove />
        </button>
      )}
      {editor.can().deleteTable() && (
        <button
          data-tooltip="Delete table"
          className="secondary"
          onClick={() => editor.chain().focus().deleteTable().run()}
        >
          <TbTableMinus />
        </button>
      )}
      {editor.can().mergeCells() && (
        <button
          data-tooltip="Merge cells"
          className="secondary"
          onClick={() => editor.chain().focus().mergeCells().run()}
        >
          <TbTableShortcut />
        </button>
      )}
      {editor.can().splitCell() && (
        <button
          data-tooltip="Split cell"
          className="secondary"
          onClick={() => editor.chain().focus().splitCell().run()}
        >
          <TbTableDown />
        </button>
      )}
      {editor.can().toggleHeaderColumn() && (
        <button
          data-tooltip="Toggle header column"
          className="secondary"
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
        >
          <TbTableColumn />
        </button>
      )}
      {editor.can().toggleHeaderRow() && (
        <button
          data-tooltip="Toggle header row"
          className="secondary"
          onClick={() => editor.chain().focus().toggleHeaderRow().run()}
        >
          <TbTableRow />
        </button>
      )}
      {editor.can().toggleHeaderCell() && (
        <button
          data-tooltip="Toggle header cell"
          className="secondary"
          onClick={() => editor.chain().focus().toggleHeaderCell().run()}
        >
          <TbTableRow />
        </button>
      )}
      {editor.can().mergeOrSplit() && (
        <button
          data-tooltip="Merge or split"
          className="secondary"
          onClick={() => editor.chain().focus().mergeOrSplit().run()}
        >
          <TbTableShortcut />
        </button>
      )}
      {editor.can().fixTables() && (
        <button
          data-tooltip="Fix table"
          className="secondary"
          onClick={() => editor.chain().focus().fixTables().run()}
        >
          <TbTableFilled />
        </button>
      )}
      {editor.can().goToNextCell() && (
        <button
          data-tooltip="Go to next cell"
          className="secondary"
          onClick={() => editor.chain().focus().goToNextCell().run()}
        >
          <TbArrowBadgeRight />
        </button>
      )}
      {editor.can().goToPreviousCell() && (
        <button
          data-tooltip="Go to previous cell"
          className="secondary"
          onClick={() => editor.chain().focus().goToPreviousCell().run()}
        >
          <TbArrowBadgeLeft />
        </button>
      )}
    </>
  );
}
