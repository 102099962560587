import { Editor } from "@tiptap/react";
import FileUpload from "./FileUpload";
import { StorageApi } from "@mihhdu/api-client";
import {
  FaList,
  FaFileAudio,
  FaPerbyte,
  FaFileImage,
  FaFileVideo,
  FaYoutube,
} from "react-icons/fa6";
import { TbTable } from "react-icons/tb";
import { VscHorizontalRule } from "react-icons/vsc";
import { FcTodoList } from "react-icons/fc";
import { BiMessageAltEdit, BiMessageAltX } from "react-icons/bi";
import { TbMessage2Down } from "react-icons/tb";
interface FloatingMenuBarProps {
  addImage: () => void;
  addAudio: () => void;
  addVideo: () => void;
  addYoutube: () => void;
  addIFrame: () => void;
  editor: Editor;
  storage: StorageApi;
  tenant: string;
}

export function FloatingMenuBar({
  addImage,
  addAudio,
  addVideo,
  addYoutube,
  addIFrame,
  editor,
  storage,
  tenant,
}: FloatingMenuBarProps) {
  const handleFileUploadComplete = (htmlTag: string) => {
    editor.chain().focus().insertContent(htmlTag).run();
  };

  return (
    <div className="floating-menu">
      <button
        data-tooltip="Bullet list"
        className="secondary"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FaList />
      </button>
      <button
        data-tooltip="Horizontal rule"
        className="secondary"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <VscHorizontalRule />
      </button>
      <button
        data-tooltip="Toggle task list"
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={editor.isActive("taskList") ? "contrast" : "secondary"}
      >
        <FcTodoList />
      </button>
      <button
        data-tooltip="Insert image from link"
        onClick={addImage}
        className="secondary"
      >
        <FaFileImage />
      </button>
      <button
        data-tooltip="Insert audio file from link"
        onClick={addAudio}
        className="secondary"
      >
        <FaFileAudio />
      </button>
      <button
        data-tooltip="Insert video file from link"
        onClick={addVideo}
        className="secondary"
      >
        <FaFileVideo />
      </button>
      <button
        data-tooltip="Embed Youtube clip"
        onClick={addYoutube}
        className="secondary"
      >
        <FaYoutube />
      </button>
      <button
        data-tooltip="Embed iFrame"
        onClick={addIFrame}
        className="secondary"
      >
        <FaPerbyte />
      </button>
      <button
        data-tooltip="Insert 3x3 table"
        className="secondary"
        onClick={() =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
      >
        <TbTable />
      </button>
      <button
        data-tooltip="Set details"
        onClick={() => editor.chain().focus().setDetails().run()}
        disabled={!editor.can().setDetails()}
        className="secondary"
      >
        <BiMessageAltEdit />
      </button>
      <button
        data-tooltip="Unset details"
        onClick={() => editor.chain().focus().unsetDetails().run()}
        disabled={!editor.can().unsetDetails()}
        className="secondary"
      >
        <BiMessageAltX />
      </button>
      <button
        data-tooltip="Force open first details"
        onClick={() =>
          editor
            .chain()
            .focus()
            .command(({ tr }) => {
              let pos = 0;
              tr.doc.descendants((node, position) => {
                if (node.type.name === "details") {
                  pos = position;
                  return false;
                }
              });
              tr.setNodeAttribute(pos, "open", true);
              return true;
            })
            .run()
        }
        className="secondary"
      >
        <TbMessage2Down />
      </button>
      <FileUpload
        tenant={tenant}
        onUploadComplete={handleFileUploadComplete}
        storage={storage}
      />
    </div>
  );
}
